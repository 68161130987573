<script setup lang="ts">
import { onUpdated, type Ref } from "vue";
import { ref, toRefs } from "vue";
import { useDateFormat } from "@vueuse/core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type {
    JourneySelectorViewModel,
    JourneyFaresModal,
    ReturnPackageSelection,
    SelectedSingleFare,
    FaresByKeyModel, TicketDetailsByKey,
} from "@/models/JourneySelectorViewModel";
import TicketValiditySummary from "@/components/3-Basket/TicketValiditySummary.vue";
import { JourneyViewType } from "@/models/basket/JourneyViewType";

const props = withDefaults(defineProps<{
  searchResults: JourneySelectorViewModel,
  selectedOutbound: SelectedSingleFare | null,
  selectedReturn: SelectedSingleFare | null,
  packageDeselected?: boolean,
  isOpenReturn?: boolean,
}>(), {
    selectedOutbound: null,
    selectedReturn: null,
    packageDeselected: false,
    isOpenReturn: false,
});

const dialog: Ref<boolean> = ref(false);
const { searchResults } = toRefs(props);
const { selectedOutbound } = toRefs(props);
const { selectedReturn } = toRefs(props);
const { isOpenReturn } = toRefs(props);
const { packageDeselected } = toRefs(props);
const availableFares: Ref<string[]> = ref([]); // fare keys list
const faresDetails: Ref<FaresByKeyModel[]> = ref([]);
const furtherTicketDetails: Ref<TicketDetailsByKey[]> = ref([]);
const graphQlSearchId: Ref<string> = ref("");
const journeyInKey: Ref<string> = ref("");
const journeyOutKey: Ref<string> = ref("");
const emit = defineEmits(["returnPackageSelected"]);
const selectedId: Ref<string> = ref("");
const selectedName: Ref<string> = ref("");
const selectedPrice: Ref<string> = ref("");

onUpdated(() => {
    // log the selectedOutbound and selectedReturn
    // along with "JourneyPackage.vue"
    // eslint-disable-next-line
    console.log("JourneyPackage.vue selectedOutbound", selectedOutbound.value);
    // eslint-disable-next-line
    console.log("JourneyPackage.vue selectedReturn", selectedReturn.value);
    if (packageDeselected.value) {
        selectedId.value = "";
        selectedName.value = "";
        selectedPrice.value = "";
    }
});

function formatDate(date: string | undefined) {
    return useDateFormat(date, "HH:mm").value;
}

function setModalData(openReturn: boolean) {
    let selectedFare :JourneyFaresModal|undefined;
    if (openReturn) {
        selectedFare = searchResults.value.journeyFares!.find((jf: JourneyFaresModal) => jf.journeyOutKey! === selectedOutbound.value!.journeyKey);
    } else {
        selectedFare = searchResults.value.journeyFares!.find((jf: JourneyFaresModal) => jf.journeyOutKey! === selectedOutbound.value!.journeyKey && jf.journeyInKey! === selectedReturn.value?.journeyKey);
    }
    if (!selectedFare) {
        console.warn("No selected fare found", searchResults.value.journeyFares);
        return;
    }
    availableFares.value = [...selectedFare?.firstClass?.returnFares as string[], ...selectedFare?.standardClass?.returnFares as string[]];
    availableFares.value.forEach((fare) => {
        const faresByKeyModel = searchResults.value.faresByFareKey[Number.parseInt(fare, 10)];
        faresDetails.value.push(faresByKeyModel);
        furtherTicketDetails.value.push(searchResults.value.ticketDetailsByTicketDetailsKey[faresByKeyModel.ticketDetailsKey]);
    });
    graphQlSearchId.value = searchResults.value.searchId!;
    journeyInKey.value = selectedFare?.journeyInKey!;
    journeyOutKey.value = selectedFare?.journeyOutKey!;
}

function getAlternative(selectedFare:string, fareType: JourneyViewType) {
    const alternativeJourneyFares = searchResults.value.journeyFares!.filter((fare:JourneyFaresModal) => fare.standardClass.returnFares.includes(selectedFare));
    const alternativeTimes: string[] = [];
    let formattedAlternativeTimes: string = "";
    if (fareType === JourneyViewType.Outbound) {
        const altOut = searchResults.value.allJourneys!.filter((obj) => alternativeJourneyFares.some((item) => item.journeyOutKey === obj.key));
        altOut.forEach((i) => alternativeTimes.push(formatDate(i.departing)));
        formattedAlternativeTimes = alternativeTimes.length > 0 ? `Out: ${alternativeTimes.toString()}` : "";
    } else {
        const altIn = searchResults.value.allJourneys!.filter((obj) => alternativeJourneyFares.some((item) => item.journeyInKey === obj.key));
        altIn.forEach((i) => alternativeTimes.push(formatDate(i.departing)));
        formattedAlternativeTimes = alternativeTimes.length > 0 ? `Return: ${alternativeTimes.toString()}` : "";
    }
    return formattedAlternativeTimes;
}

function selectNewFare(id: string) {
    const fareObject = searchResults.value.faresByFareKey[Number.parseInt(id, 10)];
    selectedId.value = id;
    selectedName.value = fareObject.displayName;
    selectedPrice.value = fareObject.priceFormatted;
}

function showDialog() {
    if (searchResults.value && selectedOutbound.value && selectedReturn.value) {
        setModalData(false);
        dialog.value = true;
    } else if (isOpenReturn.value) {
        setModalData(true);
        dialog.value = true;
    }
}

function formatTickets(tickets: any) {
    return `${tickets[0].count} ${tickets[0].personType}`;
}

function updateSelection() {
    const fareObject = searchResults.value.faresByFareKey[Number.parseInt(selectedId.value, 10)];
    const ticketDetailsKey = fareObject.ticketDetailsKey as string;
    const ticketDetails = searchResults.value.ticketDetailsByTicketDetailsKey[ticketDetailsKey];
    const ticketRestrictions = searchResults.value.ticketRestrictionsByRestrictionKey![fareObject.restrictionCode]!;
    if (selectedId.value !== "") {
        emit("returnPackageSelected", {
            outKey: journeyOutKey.value,
            inKey: journeyInKey.value,
            searchId: graphQlSearchId.value,
            packageId: selectedId.value,
            ticketType: selectedName.value,
            ticketPrice: selectedPrice.value,
            fareObject,
            ticketDetails,
            ticketRestrictions,
        } as ReturnPackageSelection);
    }
    dialog.value = false;
}

defineExpose({
    showDialog,
});
</script>

<template>
    <div class="text-center">
        <v-dialog v-model="dialog" persistent scrollable width="auto">
            <v-card>
                <v-card-title class="text-center">
                    Available Return Fares
                    <a class="float-end" @click="dialog = false">
                        <font-awesome-icon icon="fa-5 fa-circle-xmark"></font-awesome-icon>
                    </a>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <div class="row pt-5 fares">
                        <div>
                            Alternative return fares from {{ searchResults?.fromName }} to {{ searchResults?.toName }}:
                            <div v-if="availableFares.length === 0">
                                <v-empty-state
                                    text="The route may be using single-leg pricing, or you may have selected an outbound/return on different days on a route without period return tickets. Please buy two singles instead."
                                    title="There are no return fares for your selected journeys."
                                ></v-empty-state>
                            </div>
                            <div class="pb-5 mt-5 fare" v-for="(fare, i) in availableFares" :key="i">
                                <div class="fare-title pl-5 pt-3 pb-3">
                                    {{ faresDetails[i].displayName }}
                                    <a href="#" data-toggle="tooltip" data-placement="top" :title="formatTickets(searchResults?.faresByFareKey[fare].tickets)">
                                        <span class="float-right pr-5">{{ faresDetails[i].priceFormatted }}</span>
                                    </a>
                                </div>
                                <div class="fare-details-wrapper pl-5 pt-3">
                                    {{ getAlternative(fare, JourneyViewType.Outbound) }}<br>
                                    {{ getAlternative(fare, JourneyViewType.Inbound) }}
                                    <div class="fd-lhs" v-if="faresDetails[i].notes.length > 0">
                                        <div class="notes" v-for="(note, n) in faresDetails[i].notes" :key="n">
                                            {{ note.value }}
                                        </div>
                                        <div class="notes other-information"> <!-- always display this -->
                                            <TicketValiditySummary :furtherTicketDetails="furtherTicketDetails[i]"></TicketValiditySummary>
                                            <p v-if="faresDetails[i].restrictionCode"> <!-- link out to https://www.nationalrail.co.uk/{CODE} -->
                                                <a :href="`https://www.nationalrail.co.uk/${faresDetails[i].restrictionCode}`" target="_blank">
                                                    Restriction {{ faresDetails[i].restrictionCode }} applies
                                                </a>
                                            </p>
                                            <p v-else>
                                                No time restrictions apply to this ticket type.
                                                <!-- Are we using a railcard? -->
                                            </p>
                                            {{ faresDetails[i].railcards ? 'There may be additional restrictions due to the selected railcard. Check Ts and Cs.' : '' }}
                                        </div>
                                    </div>
                                    <v-btn
                                        :class="{ 'selected': fare === selectedId, 'fd-rhs': true }"
                                        class="primary-button mr-3"
                                        @click="selectNewFare(fare)">
                                        <div v-if="fare !== selectedId">Select<span class="d-md-none d-sm-inline"> this fare</span></div>
                                        <div v-if="fare === selectedId">Selected</div>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn variant="text" class="primary-button" @click="updateSelection()">
                        Update Selection
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped lang="scss">

.v-card {

  .v-divider {
    margin: 0;
  }

  .v-card-actions {
    button {
      background-color: var(--vt-c-orange);
      color: #fff;
      text-transform: capitalize;
    }
  }

  .v-card-text {
    display: grid;
  }
}

.fare-details-wrapper {
  display: grid;
  grid-template-columns: 1fr minmax(125px, min-content);
  grid-template-rows: 1fr;
  grid-template-areas: "fd-lhs fd-rhs";
  gap: 1rem;
}

.fd-lhs {
  grid-area: fd-lhs;
}

.fd-rhs {
  grid-area: fd-rhs;
}

@media only screen and (max-width: 760px) {
  .fare-details-wrapper {
    grid-template-columns: 1fr;
    /* and reverse the order */
    grid-template-areas:
        "fd-rhs"
        "fd-lhs";
  }

}

.fares .fare {
  border: 2px solid var(--vt-c-divider-dark-1);
  border-radius: 0.3rem;
}

.fare-title {
  font-weight: 600;
  border-bottom: 2px solid var(--vt-c-divider-dark-1);

  span {
    font-weight: 600;
  }
}

.fare-body {
  min-height: 6rem;

  .notes {
    width: 64%;
  }
}

.float-right.pl-5 {
  line-height: 4;
}

.v-btn {
  box-shadow: none;
}

.selected {
  background-color: var(--gray-400)
}

.fare-title {
  font-size: 0.9rem;
}

</style>

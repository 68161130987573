import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import {
    email,
    required,
} from "@vee-validate/rules";
import { defineRule } from "vee-validate";

import * as Sentry from "@sentry/vue";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
import { Amplify } from "aws-amplify";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faArrowRightArrowLeft,
    faCalendar,
    faClock,
    faUpRightFromSquare,
    faCheck,
} from "@fortawesome/pro-duotone-svg-icons";

// Import specific icons
import {
    faBus,
    faCirclePlus,
    faExchange,
    faRoute,
    faShip,
    faSignIn,
    faSignOut,
    faTimesCircle,
    faArchway,
    faArrowCircleRight,
    faArrowCircleUp,
    faArrowCircleDown,
    faArrowRight,
    faArrowLeft,
    faChevronDown,
    faChevronUp,
    faChevronRight,
    faChevronLeft,
    faCircleXmark,
    faTrain,
    faTrainSubwayTunnel,
    faUserFriends,
    faAddressCard,
    faExclamationTriangle,
    faArrowUp,
    faRepeat,
    faWalking,
    faTrash,
    faEnvelopeOpenText,
    faPrint,
    faComputerClassic,
    faQrcode,
    faTicket,
    faPlus,
    faCircleCheck,
    faHourglass,
    faCircleInfo,
    faGift,
    faIdCard,
    faLock,
} from "@fortawesome/pro-solid-svg-icons";

import PrimeVue from "primevue/config";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { retailService } from "@/services/RetailService";
import { retailInstanceTypeSymbol, retailServiceSymbol } from "@/models/Symbols";
import { RetailInstanceTypeService } from "@/services/RetailInstanceTypeService";
import constructRouter from "./router";
import App from "./App.vue";
// theme
import "primevue/resources/themes/lara-light-indigo/theme.css";
// core
import "primevue/resources/primevue.min.css";

import "./assets/main.scss";

defineRule("required", required);
defineRule("email", email);

// Register the icon
// Do this for all icons you plan to use
library.add(
    faArrowRightArrowLeft,
    faCirclePlus,
    faBus,
    faCalendar,
    faClock,
    faExchange,
    faRoute,
    faShip,
    faSignIn,
    faSignOut,
    faTimesCircle,
    faArchway,
    faArrowCircleRight,
    faArrowCircleUp,
    faArrowCircleDown,
    faArrowRight,
    faChevronDown,
    faChevronUp,
    faChevronRight,
    faChevronLeft,
    faCircleXmark,
    faCircleInfo,
    faCheck,
    faTrain,
    faTrainSubwayTunnel,
    faUserFriends,
    faAddressCard,
    faExclamationTriangle,
    faUpRightFromSquare,
    faArrowUp,
    faRepeat,
    faWalking,
    faArrowLeft,
    faTrash,
    faEnvelopeOpenText,
    faPrint,
    faComputerClassic,
    faQrcode,
    faTicket,
    faPlus,
    faCircleCheck,
    faHourglass,
    faGift,
    faIdCard,
    faLock,
);

const cognitoSettings: {userPoolId: string, userPoolClientId: string} = { userPoolId: "", userPoolClientId: "" };
const instanceTypeSettings: {instanceType: string} = { instanceType: "B2C" };

/** Begin dragons */
if (window.performance && performance.getEntriesByType) {
    const navTiming = performance.getEntriesByType("navigation");
    if (navTiming.length > 0) {
        const { serverTiming } = navTiming[0] as PerformanceResourceTiming;
        if (serverTiming && serverTiming.length > 0) {
            for (let i = 0; i < serverTiming.length; i++) {
                // if name is CognitoUserPool or CognitoUserPoolClient
                // we've smuggled the data across without needing
                // another XHR
                if (serverTiming[i].name === "CognitoUserPool") {
                    cognitoSettings.userPoolId = serverTiming[i].description;
                } else if (serverTiming[i].name === "CognitoUserPoolClient") {
                    cognitoSettings.userPoolClientId = serverTiming[i].description;
                } else if (serverTiming[i].name === "RetailInstanceType") {
                    instanceTypeSettings.instanceType = serverTiming[i].description;
                }
            }
        }
    }
}
/** End dragons */

const vuetify = createVuetify({
    theme: {
        defaultTheme: "light",
    },
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            mdi,
        },
    },
    components,
    directives,
});
const app = createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon);
app.provide(retailServiceSymbol, retailService);
const retailInstanceTypeService = new RetailInstanceTypeService(instanceTypeSettings.instanceType);
app.provide(retailInstanceTypeSymbol, retailInstanceTypeService);
const plugin = createPinia();
plugin.use(piniaPluginPersistedState);

app.use(plugin);
const router = constructRouter(retailInstanceTypeService);

if (window.location.hostname === "booking-system-test.rocketonrail.co.uk") {
    Sentry.init({
        app,
        dsn: "https://4f67506c9fb68f0f99fda3bd7a9b23c7@o4506259106365440.ingest.sentry.io/4506259135004672",
        release: "b0bff96",
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
        ],

        tracesSampleRate: 0.2,
    });
}

app.use(router);
app.use(PrimeVue);
app.use(vuetify);
app.mount("#app");

// if we don't have the cognito settings, log an error
if (!cognitoSettings.userPoolId || !cognitoSettings.userPoolClientId) {
    console.error("Cognito settings not available");
} else {
    // eslint-disable-next-line no-console
    console.info("Booting Amplify Auth with settings", cognitoSettings);
}

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: cognitoSettings.userPoolId,
            userPoolClientId: cognitoSettings.userPoolClientId,
            allowGuestAccess: true,
            signUpVerificationMethod: "code", // 'code' | 'link',
            identityPoolId: "",
        },
    },
});
